import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const MainMenu = () => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="mx-auto m-20 flex items-center align-middle grid gap-4 grid-rows-3">
        <Button
          fullWidth
          size="large"
          variant="outlined"
          onClick={() =>
            navigate("/scienceform", {
              state: {
                isRedirected: true,
              },
            })
          }
        >
          The 20 big questions in science
        </Button>

        <Button
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => navigate("/formtwo")}
        >
          Form 2
        </Button>

        <Button
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => navigate("/formthree")}
        >
          Form 3
        </Button>
      </div>
    </div>
  );
};

export default MainMenu;
