import React, { Component } from "react";
import Button from "@mui/material/Button";

class CounterState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  increment() {
    // need setState to show changes in UI : it rerenders it
    // this.setState(
    //   {
    //     count: this.state.count + 1,
    //   },
    //   () => console.log("Callback value", this.state.count)
    // );

    this.setState((prevState, props) => ({
      count: prevState.count + 1,
    }));
    console.log(this.state.count);
  }

  incrementFive() {
    this.increment();
    this.increment();
    this.increment();
    this.increment();
    this.increment();
  }

  render() {
    return (
      <div>
        <div>Count - {this.state.count}</div>
        <Button variant="outlined" onClick={() => this.incrementFive()}>
          Increment
        </Button>
      </div>
    );
  }
}

export default CounterState;
