import React, { Component } from "react";

class Welcome extends Component {
  render() {
    return (
      <div>
        <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl">
          Welcome to {this.props.projectName}
          <br />
          Start your journey FORM below.
        </h2>
        {this.props.children}
      </div>
    );
  }
}

export default Welcome;
