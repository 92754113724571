import React, { Component } from "react";
import CounterState from "../components/CounterState";

class Learn extends Component {
  render() {
    return <CounterState />;
  }
}

export default Learn;
