import React from "react";
import Welcome from "../components/WelcomeClass";
import MainMenu from "../components/MainMenu";

const Home = () => {
  return (
    <div>
      <Welcome projectName="VortexBrain">
        <p className="mt-6 text-lg leading-8 text-gray-800">
          This is the live version of the research project conducted.
        </p>
      </Welcome>
      <MainMenu />
    </div>
  );
};

export default Home;
