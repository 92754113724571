import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import ScienceFormPage from "./pages/ScienceFormPage";
import FormTwoPage from "./pages/FormTwoPage";
// import FormThreePage from "./pages/FormThreePage";
import Learn from "./pages/Learn";

function App() {
  return (
    <div className="App bg-white m-20">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/scienceform" element={<ScienceFormPage />} />
        <Route path="/formtwo" element={<FormTwoPage />} />
        {/* <Route path="/formthree" element={<FormThreePage />} /> */}
        <Route path="/learn" element={<Learn />} />
      </Routes>
    </div>
  );
}

export default App;
