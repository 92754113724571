import { useState, useEffect } from "react";
import { Button, TextField, Stack, Card } from "@mui/material";

const FormTwoPage = () => {
  const [code, setCode] = useState([]);
  const [googleColabSharingLink, setGoogleColabSharingLink] = useState("");
  const [pythonCodeTemplateString, setPythonCodeTemplateString] = useState(
    `print("Hello, World!")`
  );
  const [isRunning, setIsRunning] = useState(false);
  const [error, setError] = useState(null);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    console.log(code);
    const formattedPythonCode = code.join("").trim();
    const pythonCodeTemplateString = `${formattedPythonCode}`;
    console.log(pythonCodeTemplateString);
    setPythonCodeTemplateString(pythonCodeTemplateString.toString());
    localStorage.setItem("pythonCodeTemplateString", pythonCodeTemplateString);
    // const userCodeField = document.getElementById("userCode");
    // userCodeField.innerHTML = pythonCodeTemplateString;
  }, [code]);

  // useEffect(() => {
  //   const storedCode = localStorage.getItem("pythonCodeTemplateString");
  //   if (storedCode) {
  //     setPythonCodeTemplateString(storedCode);
  //   }
  // }, []);

  const handleGoogleColabSharingLinkChange = (event) => {
    setGoogleColabSharingLink(event.target.value);
  };

  // const googleColabFetch = async () => {
  //   console.log("Initiating fetch for Google Colab...");

  //   const colabSharingUrl = googleColabSharingLink.trim();
  //   // const colabSharingUrl = "https://colab.research.google.com/drive/1COc3QJuamSlxBomFjs6lfgE_ZjZCRH-l?usp=sharing";
  //   const regex = /\/drive\/([-\w]+)/;
  //   if (!regex.test(colabSharingUrl)) {
  //     console.error("Invalid Google Colab sharing link!");
  //     return;
  //   } else {
  //     console.log("Google Colab URL Health: Good => " + colabSharingUrl);
  //   }

  //   // Extract the ID from the URL using the regular expression
  //   const match = colabSharingUrl.match(regex);
  //   const colabId = match[1];
  //   const colabDownloadUrl = `https://docs.google.com/uc?export=download&id=${colabId}`;
  //   console.log(colabDownloadUrl);

  //   try {
  //     var response = await fetch(colabDownloadUrl);
  //     var data = await response.json();
  //     console.log(data);
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   for (let i = 0; i < data["cells"].length; i++) {
  //     if (data["cells"][i]["cell_type"] === "code") {
  //       let codeBlock = data["cells"][i]["source"];
  //       // codeBlock = ["<p>", ...codeBlock, "</p>", "\n\n"];
  //       codeBlock = [...codeBlock, "\n\n"];
  //       setCode((prevCode) => prevCode.concat(codeBlock));
  //     }
  //   }
  // };

  const googleColabFetchStop = async () => {
    setIsRunning(false);
    console.log("Fetching stopped.");
  };

  const googleColabFetch = async () => {
    console.log("Initiating fetch for Google Colab...");

    setCode([]);
    setIsRunning(true);
    setError(null);
    setCounter((prevCounter) => prevCounter + 1);

    const colabSharingUrl = googleColabSharingLink.trim();
    const regex = /\/drive\/([-\w]+)/;
    const match = colabSharingUrl.match(regex);
    if (!match) {
      setIsRunning(false);
      setError("Invalid URL!");
      console.log("Invalid URL!");
      return;
    }
    const colabId = match[1];
    // const colabDownloadUrl = `/google-drive-proxy/uc?export=download&id=${colabId}`;
    const colabDownloadUrl = `https://docs.google.com/uc?export=download&id=${colabId}`;
    console.log(colabDownloadUrl);
    try {
      const response = await fetch(colabDownloadUrl);
      const dataText = await response.text();
      console.dir(response);
      console.log("Raw Response: " + dataText);
      const data = JSON.parse(dataText);
      console.log(data);

      // const newCode = data.cells
      //   .filter((cell) => cell.cell_type === "code")
      //   .map((cell) => cell.source)
      //   .flat();
      // setCode(newCode);

      for (let i = 0; i < data["cells"].length; i++) {
        if (data["cells"][i]["cell_type"] === "code") {
          let codeBlock = data["cells"][i]["source"];
          // codeBlock = ["<p>", ...codeBlock, "</p>", "\n\n"];
          codeBlock = [...codeBlock, "\n\n"];
          setCode((prevCode) => prevCode.concat(codeBlock));
        }
      }
    } catch (error) {
      console.error(error);
      setIsRunning(false);
      setError("Failed to fetch code from Google Colab!");
    }
  };

  useEffect(() => {
    let intervalId = null;
    if (isRunning) {
      intervalId = setInterval(() => {
        googleColabFetch();
      }, 10000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);

  return (
    <div>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <h1>Google Colab</h1>
        <TextField
          sx={{ width: "71ch" }}
          id="googleColabSharingLink"
          label="Google Colab Sharing Link"
          variant="outlined"
          value={googleColabSharingLink}
          onChange={handleGoogleColabSharingLinkChange}
        />
        <div>
          <Button onClick={googleColabFetch}>
            {isRunning ? (
              <span style={{ color: "green" }}>Fetching... {counter}</span>
            ) : (
              "Start Fetching Code from Google Colab!"
            )}
          </Button>
          <Button onClick={googleColabFetchStop}>
            <span style={{ color: "red" }}>Stop Fetching</span>
          </Button>
        </div>

        {error && <div style={{ color: "red" }}>{error}</div>}
        <div>
          <Card
            variant="outlined"
            sx={{ minWidth: 500, minHeight: 200, padding: 5 }}
          >
            <pre>
              <code className={`language-python`} data-ln-start-from="1">
                {pythonCodeTemplateString}
              </code>
            </pre>
          </Card>
        </div>
      </Stack>
    </div>
  );
};

export default FormTwoPage;

// https://colab.research.google.com/drive/1COc3QJuamSlxBomFjs6lfgE_ZjZCRH-l?usp=sharing
// https://colab.research.google.com/drive/1sRZ1zzM_2tK_5iFIbwDzrGWVpx_oUF1Y?usp=sharing
