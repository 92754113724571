import React, { useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../components/documenteditor.css";

const ScienceFormPage = () => {
  const toolBarRef = useRef(null);
  const location = useLocation();
  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "heading",
      "|",
      "fontSize",
      "fontFamily",
      "|",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "highlight",
      "horizontalLine",
      "|",
      "subscript",
      "superscript",
      "|",
      "alignment",
      "|",
      "numberedList",
      "bulletedList",
      "|",
      "outdent",
      "indent",
      "|",
      "todoList",
      "link",
      "blockQuote",
      "imageInsert",
      // "imageUpload",
      "insertTable",
      "mediaEmbed",
      "|",
      "undo",
      "redo",
      "|",
      "findAndReplace",
      "|",
      "htmlEmbed",
      "|",
      "textPartLanguage",
    ],
  };

  // Define a state variable to store the editor data
  const [editorData, setEditorData] = useState("");

  if (location?.state?.isRedirected) {
    return (
      <div className="bg-grey m-5">
        <h3 className="text-3xl font-bold tracking-tight text-black">
          The 20 big questions in science:
        </h3>
        <div class="document-editor">
          <div ref={toolBarRef} class="document-editor__toolbar"></div>
          <div class="document-editor__editable-container">
            <div class="document-editor__editable">
              <CKEditor
                editor={Editor}
                config={editorConfiguration}
                data=""
                onReady={(editor) => {
                  if (toolBarRef.current) {
                    toolBarRef.current.appendChild(
                      editor.ui.view.toolbar.element
                    );
                  }
                }}
                onInit={(editor) => {
                  // Add the toolbar to the container
                  const toolbarContainer = document.querySelector(
                    "#document-editor__toolbar"
                  );
                  toolbarContainer.appendChild(editor.ui.view.toolbar.element);

                  window.editor = editor;
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorData(data);
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <p>The content of the editor is:</p>
          <div>{editorData}</div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default ScienceFormPage;
